import { RecordValue } from "../sim/Value";
import { createUIForRecordValue } from "../sim_ui/Value_ui";
import { addCol, addLabel, addRow, createBasicCard, createFormCard, createInput as createInput, makeAccordion, makeCodeBlock, makeIconButton, makeModal } from "./Utils";
import { adjectives, colors, uniqueNamesGenerator } from 'unique-names-generator';
import { ModChain, ResourceManager, lsPrefix, Mod, DataFiles, makeLSKey } from "./resourcemanager";
import { PageState } from "./PageState";

export class ModManager
{
	private base = { mod: { name: '1.51', root: '/data/1.51' }, fallback: undefined };
	private mods: ModChain[] = [];

	private _selected: ModChain = this.base;
	private select: HTMLSelectElement;

	private lsModKey = 'modlist';
	private lsMods: ModChain[] = [];

	private _resources: ResourceManager;

	public get resources()
	{
		return this._resources;
	}

	public get selected()
	{
		return this._selected;
	}

	public set selected(mod: ModChain)
	{
		this._selected = mod;
		this.updateSelector();

		this._resources = new ResourceManager(this._selected);
	}

	constructor(private modSelectorId: string, private onChange: () => void, pageState: PageState)
	{
		this.mods = [
			this.base,
			{ mod: { name: 'Overhaul 6', root: '/data/Overhaul' }, fallback: this.base },
			{ mod: { name: 'Overhaul 7', root: '/data/Overhaul7WIP' }, fallback: this.base },
			{ mod: { name: 'Emperor Wars 4.0h', root: '/data/EW_40h' }, fallback: this.base },
			{ mod: { name: 'Emperor Wars 4.1', root: '/data/EW_41b' }, fallback: this.base },
			{ mod: { name: 'Emperor Wars 4.2.8', root: '/data/ew_42' }, fallback: this.base },
			{ mod: { name: 'Vau Wars 1.01', root: '/data/vw_101' }, fallback: this.base },
			{ mod: { name: 'Symbiot Wars 1.0', root: '/data/sw_10' }, fallback: this.base },
			{ mod: { name: 'Nova2.4', root: '/data/Nova2.4' }, fallback: this.base },
		];

		this.lsMods = this.loadLSModList();
		this.mods = this.mods.concat(this.lsMods);

		this.select = document.getElementById(this.modSelectorId) as HTMLSelectElement;

		const mod = pageState.getParam('mod');
		if (mod)
		{
			const selected = this.mods.find((chain) => chain.mod.root === mod);
			if (selected)
				this._selected = selected;
		}

		this.rebuildSelector();

		this.select.addEventListener('change', () =>
		{
			const selected = this.mods.find((chain) => chain.mod.root === this.select.value);
			if (selected)
				this.selected = selected;
			onChange();
		});

		this._resources = new ResourceManager(this._selected);
	}

	private rebuildSelector()
	{
		Array.from(this.select.options).forEach((option) =>
		{
			this.select.remove(option.index);
		});

		this.mods.forEach((chain) =>
		{
			const option = document.createElement('option');
			option.text = chain.mod.name;
			option.value = chain.mod.root;

			if (this._selected.mod.root === chain.mod.root)
				option.selected = true;

			this.select.add(option);
		});
	}

	private updateSelector()
	{
		Array.from(this.select.options).forEach((option) =>
		{
			if (option.value === this._selected.mod.root)
				option.selected = true;

			else
				option.selected = false;
		});
	}

	public addLSMod(name: string, base: ModChain)
	{
		const chain = { mod: { name, root: `${lsPrefix}${name}` }, fallback: base };

		this.mods.push(chain);

		const option = document.createElement('option');
		option.text = name;
		option.value = chain.mod.root;
		this.select.add(option);

		this.lsMods.push(chain);
		this.saveLSModList();

		this.selected = this.mods.find((chain) => chain.mod.root === chain.mod.root) || this.base;

		this.updateSelector();

		return chain;
	}

	private saveModFile(mod: Mod, entry: keyof DataFiles, data: string)
	{
		if (mod.root.startsWith(lsPrefix))
		{
			const key = makeLSKey(entry, mod);
			localStorage.setItem(key, data);
		}
	}

	private loadLSModList()
	{
		return JSON.parse(localStorage.getItem(this.lsModKey) || "[]") as ModChain[];
	}

	private saveLSModList()
	{
		localStorage.setItem(this.lsModKey, JSON.stringify(this.lsMods));
	}

	private deleteLSMod(mod: Mod)
	{
		this.lsMods = this.lsMods.filter(m => m.mod.root !== mod.root);
		this.saveLSModList();

		for (const [key, value] of Object.entries(this.resources.datFiles))
		{
			localStorage.removeItem(makeLSKey(key as keyof DataFiles, mod));
		}

		this.mods = this.mods.filter(m => m.mod.root !== mod.root);
		if (this.selected.mod.root === mod.root)
		{
			this._selected = this.base;
			this.rebuildSelector();
			this.onChange();
		}
	}

	public async uploadFileAsText(file: File): Promise<{ name: string; data: string; }>
	{
		return new Promise((resolve, reject) =>
		{
			const reader = new FileReader();

			reader.onload = (e) =>
			{
				const data = e.target!.result as string;
				resolve({ name: file.name, data });
			};
			reader.readAsText(file);
			reader.onerror = reject;
		});
	}

	public async onManageMods()
	{
		const makeName = () => uniqueNamesGenerator({ dictionaries: [adjectives, colors], length: 2, separator: ' ', style: 'capital' });

		const addModCard = (parent: HTMLElement, mod: ModChain) =>
		{
			const { form, card, header } = createFormCard(parent, mod.mod.name, false, null);
			card.classList.add('h-100');
			form.classList.add('container');
			const row = addRow(form);
			row.classList.add('row-cols-1');
			addLabel(addCol(row), `Root: ${mod.mod.root}`);
			addLabel(addCol(row), `Base: ${mod.fallback?.mod.name}`);

			if (this.IsModLS(mod.mod))
			{
				const del = makeIconButton(header, 'Delete', 'bi-trash', 'danger', () =>
				{
					this.deleteLSMod(mod.mod);
					card.remove();
				});
				del.classList.add('ms-2');
				del.classList.add('float-end');

				const upload = makeIconButton(header, 'Upload .dat files', 'bi-upload', 'primary', () =>
				{
					this.uploadData(mod.mod);
				});
				upload.classList.add('float-end');

				const toJson = makeIconButton(header, 'To JSON', 'bi-file-earmark-arrow-down', 'primary', () =>
				{
					this.downloadLSModAsJson(mod);
				});
				toJson.classList.add('float-end');
			}
		};

		const addNewModCard = (parent: HTMLElement, modRow: HTMLElement) =>
		{
			const { form, card, header } = createFormCard(parent, "New local mod", false, null);
			card.classList.add('h-100');
			card.classList.add('border-primary');
			form.classList.add('container');
			const row = addRow(form);
			row.classList.add('row-cols-1');

			const { input: newModNameInput } = createInput(addCol(row), 'Name', 'text', '', () => { });
			newModNameInput.value = makeName();
			const modTypes: Record<string, string> = {};
			this.mods.forEach((mod) =>
			{
				modTypes[mod.mod.root] = mod.mod.name;
			});
			const modsSelector: RecordValue<Record<string, string>> = new RecordValue(modTypes, this._selected.mod.root, 'Base');
			createUIForRecordValue(addCol(row), modsSelector, () => { });

			const add = makeIconButton(header, 'Add', 'bi-plus', 'primary', () =>
			{
				const modName = newModNameInput.value;
				const base = this.mods.find((mod) => modsSelector.value === mod.mod.root);

				if (!base)
					return;

				const newMod = this.addLSMod(modName, base);
				addModCard(addCol(modRow), newMod);

				newModNameInput.value = makeName();
			});
			add.classList.add('ms-2');
			add.classList.add('float-end');

			const addFromJson = makeIconButton(header, 'From JSON', 'bi-file-earmark-arrow-up', 'primary', () =>
			{
				const fileInput = document.createElement('input');
				fileInput.multiple = false;
				fileInput.type = 'file';
				fileInput.addEventListener('change', async (e) =>
				{
					const files = Array.from((e.target as HTMLInputElement).files!);

					files.forEach(async (file) =>
					{
						const data = await this.uploadFileAsText(file);
						const newMod = this.loadLSModFromJson(data.data);
						addModCard(addCol(modRow), newMod);
					});
				});
				fileInput.click();
			});
			addFromJson.classList.add('float-end');
		};

		const { div: modalDiv, title, body: modalBody, footer, header, modal } = makeModal("Manage Mods");

		modalDiv.addEventListener('hidden.bs.modal', () =>
		{
			this.rebuildSelector();
		});

		modalDiv.addEventListener('shown.bs.modal', () =>
		{
			const { form: existingModsCard } = createBasicCard(modalBody, null);
			existingModsCard.classList.add('container');
			const modRow = addRow(existingModsCard);
			modRow.classList.add('row-cols-3');

			addNewModCard(addCol(modRow), modRow);

			this.mods.forEach((mod) =>
			{
				addModCard(addCol(modRow), mod);
			});
		});

		modal.show();
	}

	private saveLSModToJson(mod: ModChain): string
	{
		const data: Record<string, string> = {};
		data['__name'] = mod.mod.name;
		data['__base'] = mod.fallback?.mod.root || 'None';
		for (const [key, value] of Object.entries(this.resources.datFiles))
		{
			const stored = localStorage.getItem(makeLSKey(key as keyof DataFiles, mod.mod));
			if (stored)
				data[value] = stored;
		}

		const json = JSON.stringify(data, null, 2);
		return json;
	}

	private downloadLSModAsJson(mod: ModChain)
	{
		const json = this.saveLSModToJson(mod);

		const blob = new Blob([json], { type: 'application/json' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = `localmod ${mod.mod.name}.json`;
		a.click();
		URL.revokeObjectURL(url);
	}

	private loadLSModFromJson(json: string)
	{
		const data = JSON.parse(json) as Record<string, string>;

		const name = data['__name'];
		const base = data['__base'];
		const mod = this.addLSMod(name, this.mods.find(m => m.mod.root === base)!);

		for (const [key, value] of Object.entries(data))
		{
			const entry = this.resources.entryForName(key);
			if (entry)
			{
				this.saveModFile(mod.mod, entry, value);
			}
		}

		return mod;
	}

	public async onShowModData()
	{
		const { div: modalDiv, title, body: modalBody, footer, header, modal } = makeModal("Data");

		modalDiv.addEventListener('shown.bs.modal', () =>
		{
			const { form: dataCard } = createBasicCard(modalBody, null);

			this.refreshModDataUI(dataCard);
		});

		modal.show();
	}

	private IsModLS(mod: Mod)
	{
		return mod.root.startsWith(lsPrefix);
	}

	private uploadData(mod: Mod)
	{
		const fileInput = document.createElement('input');
		fileInput.multiple = true;
		fileInput.type = 'file';
		fileInput.accept = '.dat,.ini';
		fileInput.addEventListener('change', async (e) =>
		{
			const files = Array.from((e.target as HTMLInputElement).files!);

			files.forEach(async (file) =>
			{
				const entry = this._resources.entryForName(file.name);

				if (entry !== undefined)
				{
					const data = await this.uploadFileAsText(file);
					this.saveModFile(mod, entry, data.data);
				}
			});
		});
		fileInput.click();
	}

	private refreshModDataUI(parent: HTMLDivElement)
	{
		parent.innerHTML = '';
		const { addItem } = makeAccordion(parent, false, true, undefined);

		Object.keys(this._resources.datFiles!).forEach((file) =>
		{
			addItem(async (itemBody, itemHeader, collapse) =>
			{
				const data = await this._resources.load(file as keyof DataFiles);
				if (!data)
					return;

				const language = file === 'ini' ? 'ini' : 'plaintext';

				makeCodeBlock(itemBody, data.data, language);
				itemHeader.textContent = `${file} (${data.mod.name})`;
			});
		});
	}
}
